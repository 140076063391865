.card {
    height: 80px;
    position: relative;
    margin: 1px;
    box-shadow: 0 0 2px rgba(51, 51, 51, 0.25);
    transition: 0.3s;
    overflow: hidden;
    border-radius: 4px;
  
    &:hover {
      transform: scale(1.25);
      z-index: 2;
    }
  
    img {
      object-fit: cover;
      height: 80px;
      width: 160px;
    }
    .data-container {
      position: absolute;
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      transition: 0.2s;
  
      &:hover {
        opacity: 1;
  
        ul {
          transform: scaleY(1);
        }
      }
      ul {
        color: $color-2;
        padding-top: 5px;
        display: grid;
        height: 100%;
        width: 100%;
        text-align: center;
        transition: 0.3s;
        transform: scaleY(0.3);
        z-index: 20;
  
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.02s;
        }
        &:hover::after {
          background: rgba(0, 0, 0, 0.65);
        }
      }
      li {
        font-size: 0.8rem;
        z-index: 30;
  
        &:nth-child(1) {
          font-weight: bold;
          color: $color-1;
        }
      }
    }
  }
  