.logo {
    display: flex;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translate(-50%);
    background: url(../../assets/main.jpg) center/cover;
    height: 60px;
    width: 200px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 0px rgba(51, 51, 51, 0.5);
  
    h3 {
      color: $color-2;
      letter-spacing: 1px;
    }
  
    img {
      height: 34px;
      width: 34px;
      margin-right: 10px;
      object-fit: cover;
    }
  }
  